import * as React from "react";
import { ChevronDownIcon } from "@heroicons/react/solid";

const navigation = {
  resources: [
    { name: "Allure Report", href: "https://github.com/allure-framework" },
    { name: "Dokumentace", href: "https://docs.qameta.io/allure-testops" },
    { name: "Aktualizace", href: "https://docs.qameta.io/allure-testops/release-notes" },
  ],
  legal: [
    { name: "Accessibility", href: "/accessibility" },
    { name: "Privacy Policy", href: "/privacy-policy" },
    { name: "Terms and Conditions", href: "terms-and-conditions" },
    { name: "Terms and Conditions of Sale", href: "terms-and-conditions-of-sale" },
  ],
  social: [
    {
      name: "Twitter",
      href: "https://twitter.com/qametasoftware?lang=en",
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
        </svg>
      ),
    },
    {
      name: "Linkedin",
      href: "https://www.linkedin.com/company/14038520",
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: "Youtube",
      href: "https://www.youtube.com/channel/UCjvgLcJ_cILh30YoW_SpXJg",
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
  ],
};

const Footer1: React.FC = () => (
  <footer className="bg-gray-800" aria-labelledby="footerHeading">
    <h2 id="footerHeading" className="sr-only">
      Footer
    </h2>
    <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
      <div className="pb-8 xl:grid xl:grid-cols-5 xl:gap-8">
        <div className="grid grid-cols-2 gap-8 xl:col-span-4">
          <div className="md:grid md:grid-cols-2 md:gap-8">
            <div className="mt-12 md:mt-0">
              <h3 className="text-xs font-semibold text-gray-400 tracking-wider uppercase">
                ZDROJE
              </h3>
              <ul className="mt-4 ml-0 space-y-2">
                {navigation.resources.map((item) => (
                  <li key={item.name}>
                    <a
                      href={item.href}
                      className="text-sm pl-0 ml-0 text-gray-300 hover:text-white"
                    >
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="md:grid md:grid-cols-2 md:gap-8">
            <div className="mt-12 md:mt-0">
              <h3 className="text-xs font-semibold text-gray-400 tracking-wider uppercase">
                Legal
              </h3>
              <ul className="mt-4 ml-0 space-y-2">
                {navigation.legal.map((item) => (
                  <li key={item.name}>
                    <a href={item.href} className="text-sm text-gray-300 hover:text-white">
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="mt-12 xl:mt-0"></div>
      </div>
      <div className="mt-8 border-t border-gray-700 pt-8 md:flex md:items-center md:justify-between">
        <div className="flex space-x-6 md:order-2">
          {navigation.social.map((item) => (
            <a key={item.name} href={item.href} className="text-gray-400 hover:text-gray-300">
              <span className="sr-only">{item.name}</span>
              <item.icon className="h-6 w-6" aria-hidden="true" />
            </a>
          ))}
        </div>
        <p className="mt-8 text-sm text-gray-400 md:mt-0 md:order-1">
          &copy; 2021 Qameta Software s.r.o. All rights reserved <br />
          Nademlejnská 600/1, Hloubětín, 198 00 Praha 9
        </p>
      </div>
    </div>
  </footer>
);
export { Footer1 };
