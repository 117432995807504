import React from "react";
import { Fragment } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Popover, Transition } from "@headlessui/react";
import {
  ChartBarIcon,
  CursorClickIcon,
  DocumentReportIcon,
  MenuIcon,
  RefreshIcon,
  ShieldCheckIcon,
  ViewGridIcon,
  XIcon,
} from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";

const solutions = [
  {
    name: "Defects",
    description: "But who has any right to find fault with a man",
    href: "#",
    icon: ChartBarIcon,
  },
  {
    name: "xUnit",
    description: "But who has any right to find fault with a man",
    href: "#",
    icon: CursorClickIcon,
  },
  {
    name: "Timeline",
    description: "But who has any right to find fault with a man",
    href: "#",
    icon: ViewGridIcon,
  },
  {
    name: "Behaviors",
    description: "But who has any right to find fault with a man",
    href: "#",
    icon: RefreshIcon,
  },
  {
    name: "Graphs",
    description: "But who has any right to find fault with a man",
    href: "#",
    icon: DocumentReportIcon,
  },
];
const resources = [
  {
    name: "Webinars",
    description: "But who has any right to find fault with a man",
    href: "#",
    icon: CursorClickIcon,
  },
  {
    name: "Blog",
    description: "But who has any right to find fault with a man",
    href: "#",
    icon: CursorClickIcon,
  },
  {
    name: "Documentation",
    description: "But who has any right to find fault with a man",
    href: "#",
    icon: CursorClickIcon,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Header1: React.FC = () => (
  <Popover className="relative bg-white">
    {({ open }) => (
      <>
        <div className="flex justify-between items-center px-4 py-6 sm:px-6 md:justify-start md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <a href="/">
              <span className="sr-only">Allure Report</span>
              <StaticImage
                className="h-9 w-8 sm:h-8"
                src="../assets/images/allure-report-icon.png"
                alt="Allure Report"
              />
            </a>
          </div>
          <div className="-mr-2 -my-2 md:hidden">
            <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none ">
              <span className="sr-only">Open menu</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <Popover.Group as="nav" className="hidden md:flex space-x-10">
            {/*<Popover className="relative">*/}
            {/*  {({ open }) => (*/}
            {/*    <>*/}
            {/*      <Popover.Button*/}
            {/*        className={classNames(*/}
            {/*          open ? "text-gray-900" : "text-gray-500",*/}
            {/*          "group bg-white rounded-md inline-flex items-center text-sm font-medium hover:text-gray-900 focus:outline-none",*/}
            {/*        )}*/}
            {/*      >*/}
            {/*        <span>How it works</span>*/}
            {/*        <ChevronDownIcon*/}
            {/*          className={classNames(*/}
            {/*            open ? "text-gray-600" : "text-gray-400",*/}
            {/*            "ml-2 h-5 w-5 group-hover:text-gray-500",*/}
            {/*          )}*/}
            {/*          aria-hidden="true"*/}
            {/*        />*/}
            {/*      </Popover.Button>*/}

            {/*      <Transition*/}
            {/*        show={open}*/}
            {/*        as={Fragment}*/}
            {/*        enter="transition ease-out duration-200"*/}
            {/*        enterFrom="opacity-0 translate-y-1"*/}
            {/*        enterTo="opacity-100 translate-y-0"*/}
            {/*        leave="transition ease-in duration-150"*/}
            {/*        leaveFrom="opacity-100 translate-y-0"*/}
            {/*        leaveTo="opacity-0 translate-y-1"*/}
            {/*      >*/}
            {/*        <Popover.Panel*/}
            {/*          static*/}
            {/*          className="absolute z-10 -ml-4 mt-3 transform w-screen max-w-md lg:max-w-2xl lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"*/}
            {/*        >*/}
            {/*          <div className="rounded-lg shadow-lg overflow-hidden">*/}
            {/*            <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8 lg:grid-cols-2">*/}
            {/*              {solutions.map((item) => (*/}
            {/*                <a*/}
            {/*                  key={item.name}*/}
            {/*                  href={item.href}*/}
            {/*                  className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"*/}
            {/*                >*/}
            {/*                  <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-indigo-50 text-indigo-500 sm:h-12 sm:w-12">*/}
            {/*                    <item.icon className="h-6 w-6" aria-hidden="true" />*/}
            {/*                  </div>*/}
            {/*                  <div className="ml-4">*/}
            {/*                    <p className="mb-0 text-sm font-medium text-gray-900">*/}
            {/*                      {item.name}*/}
            {/*                    </p>*/}
            {/*                    <p className="mt-0.5 mb-0 text-sm text-gray-500">*/}
            {/*                      {item.description}*/}
            {/*                    </p>*/}
            {/*                  </div>*/}
            {/*                </a>*/}
            {/*              ))}*/}
            {/*            </div>*/}
            {/*            <div className="bg-gray-50 p-1 sm:p-8">*/}
            {/*              <a href="#" className="-m-3 px-3 flow-root rounded-md">*/}
            {/*                <div className="flex items-center">*/}
            {/*                  <div className="text-sm font-medium text-gray-900">*/}
            {/*                    Allure TestOps*/}
            {/*                  </div>*/}
            {/*                  <span className="ml-3 inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium leading-5 bg-yellow-400 text-white">*/}
            {/*                    New*/}
            {/*                  </span>*/}
            {/*                </div>*/}
            {/*                <p className="mt-0.5 mb-0 text-sm text-gray-500">*/}
            {/*                  Start our new product today!*/}
            {/*                </p>*/}
            {/*              </a>*/}
            {/*            </div>*/}
            {/*          </div>*/}
            {/*        </Popover.Panel>*/}
            {/*      </Transition>*/}
            {/*    </>*/}
            {/*  )}*/}
            {/*</Popover>*/}

            <a
              href="/#features"
              className="text-sm font-medium text-gray-500 hover:text-gray-900 py-0.5"
            >
              Funkce
            </a>
            <a
              href="/#faq"
              className="text-sm font-medium text-gray-500 hover:text-gray-900 py-0.5"
            >
              FAQ
            </a>

            {/*<Popover className="relative ">*/}
            {/*  {({ open }) => (*/}
            {/*    <>*/}
            {/*      <Popover.Button*/}
            {/*        className={classNames(*/}
            {/*          open ? "text-gray-900" : "text-gray-500",*/}
            {/*          "group bg-white rounded-md inline-flex items-center text-sm font-medium hover:text-gray-900 focus:outline-none",*/}
            {/*        )}*/}
            {/*      >*/}
            {/*        <span>Learn</span>*/}
            {/*        <ChevronDownIcon*/}
            {/*          className={classNames(*/}
            {/*            open ? "text-gray-600" : "text-gray-400",*/}
            {/*            "ml-2 h-5 w-5 group-hover:text-gray-500",*/}
            {/*          )}*/}
            {/*          aria-hidden="true"*/}
            {/*        />*/}
            {/*      </Popover.Button>*/}

            {/*      <Transition*/}
            {/*        show={open}*/}
            {/*        as={Fragment}*/}
            {/*        enter="transition ease-out duration-200"*/}
            {/*        enterFrom="opacity-0 translate-y-1"*/}
            {/*        enterTo="opacity-100 translate-y-0"*/}
            {/*        leave="transition ease-in duration-150"*/}
            {/*        leaveFrom="opacity-100 translate-y-0"*/}
            {/*        leaveTo="opacity-0 translate-y-1"*/}
            {/*      >*/}
            {/*        <Popover.Panel*/}
            {/*          static*/}
            {/*          className="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-xs sm:px-0"*/}
            {/*        >*/}
            {/*          <div className="rounded-lg shadow-lg overflow-hidden">*/}
            {/*            <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">*/}
            {/*              {resources.map((item) => (*/}
            {/*                <a*/}
            {/*                  key={item.name}*/}
            {/*                  href={item.href}*/}
            {/*                  className="-m-3 p-3 block rounded-md hover:bg-gray-50"*/}
            {/*                >*/}
            {/*                  <p className="mb-0 text-sm font-medium text-gray-900">{item.name}</p>*/}
            {/*                  <p className="mt-0.5 mb-0 text-sm text-gray-500">*/}
            {/*                    {item.description}*/}
            {/*                  </p>*/}
            {/*                </a>*/}
            {/*              ))}*/}
            {/*            </div>*/}
            {/*          </div>*/}
            {/*        </Popover.Panel>*/}
            {/*      </Transition>*/}
            {/*    </>*/}
            {/*  )}*/}
            {/*</Popover>*/}
            {/*<a href="#" className="text-sm font-medium text-gray-500 hover:text-gray-900 py-0.5">*/}
            {/*  Contacts*/}
            {/*</a>*/}
          </Popover.Group>
          {/*<div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">*/}
          {/*  <a*/}
          {/*    href="#"*/}
          {/*    className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-6 py-2 border border-transparent rounded-full shadow-sm text-sm font-medium text-white bg-indigo-500 hover:bg-indigo-600"*/}
          {/*  >*/}
          {/*    Get started*/}
          {/*  </a>*/}
          {/*</div>*/}
        </div>

        {/* Mobile */}
        <Transition
          show={open}
          as={Fragment}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            focus
            static
            className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
          >
            <div className="rounded-lg shadow-lg bg-white divide-y-2 divide-gray-50">
              <div className="pt-5 pb-6 px-5 overflow-visible">
                <div className="flex items-center justify-between overflow-visible">
                  <div className="overflow-visible">
                    <StaticImage
                      className="h-9 w-9 overflow-visible"
                      src="../assets/images/allure-report-icon.png"
                      alt="Allure Report"
                    />
                  </div>
                  <div className="-mr-2">
                    <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none ">
                      <span className="sr-only">Close menu</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
                <div className="mt-6"></div>
              </div>
              <div className="py-6 px-5">
                <div className="grid grid-cols-2 gap-4">
                  <a href="#" className="text-base font-medium text-gray-900 hover:text-gray-700">
                    How it works
                  </a>

                  <a href="#" className="text-base font-medium text-gray-900 hover:text-gray-700">
                    Features
                  </a>

                  <a href="#" className="text-base font-medium text-gray-900 hover:text-gray-700">
                    Documentation
                  </a>
                </div>
                <div className="mt-6">
                  <a
                    href="#"
                    className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-full shadow-sm text-base font-medium text-white bg-indigo-500 hover:bg-indigo-700"
                  >
                    Get started
                  </a>
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </>
    )}
  </Popover>
);

export { Header1 };
