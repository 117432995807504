import * as React from "react";
import "./layout.scss";

export const Layout: React.FC = ({ children }) => {
  return (
    <div style={{ overflowX: "hidden" }}>
      <div
        style={{
          margin: "0 auto",
          padding: "0",
        }}
      >
        <main>{children}</main>
      </div>
    </div>
  );
};
